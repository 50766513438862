<template>
  <div id="form__inquiry" class="col-lg-3 col-xl-3 position-relative">
    <div class="form__inquiry-section anchor"></div>
    <button type="button" class="btn_icon btn__close--inquiry" @click="closeFormInquiry">
      <img src="@/assets/icons/icon_close.svg" height="24">
    </button>
    <section style="padding: 0">
      <div class="sale__detail" style="padding: 15px">
        <div class="sale__detail--image">
          <img :src="sale.profileImgFullUrl" style="overflow: hidden; width: 60%; padding: 10px; border-radius: 50%;" />
        </div>
        <div class="sale__detail--contact">
          <div class="sale__name">{{sale.firstName}} {{sale.lastName}}</div>
          <div class="sale__position">{{sale.position}}</div>
          <div v-if="sale.phone !== ''" class="sale__phone"><span><img src="@/assets/icons/icon_telephone.svg" height="20" alt="icon-telephone"></span>{{sale.phone}}</div>
          <div v-if="sale.fax !== ''" class="sale__phone"><span><img src="@/assets/icons/icon_fax.png" height="20" style="opacity: 0.6" alt="icon-fax"></span>{{sale.fax}}</div>
          <div v-if="sale.email !== ''" class="sale__email"><span><img src="@/assets/icons/icon_email.svg" height="20" alt="icon-email"></span>{{sale.email}}</div>
          <div v-if="sale.lineId !== ''"  class="sale__phone"><span><img src="@/assets/icons/icon_line.png" height="20" style="opacity: 0.6" alt="icon-line"></span>{{sale.lineId}}</div>
        </div>
      </div>
      <h3 style="color: #ffffff; background: #455A64; padding: 12px 24px;">Reply to the listing</h3>
      <form style="padding: 0 15px 15px; font-size: 14px;">
        <p>Interested in renting or buying this property? Then send us an email</p>
        <div style="margin-top: 1.5em">
          <label>Your Name:</label>
          <input type="text" class="form_input" v-model="inquiry.fullName">
        </div>
        <div>
          <label>Email:</label>
          <input type="email" class="form_input" v-model="inquiry.email">
        </div>
        <div>
          <label>Phone:</label>
          <input type="tel" class="form_input" v-model="inquiry.phone">
        </div>
        <div>
          <label>Message:</label>
          <textarea  v-model="inquiry.message"></textarea>
        </div>
        <div style="margin-top: 1.5em; overflow: hidden;">
          <vue-recaptcha :sitekey="RECAPTCHA_SITE_KEY"
                         size="normal"
                         @verify="verifyRecaptcha($event)"
                         @expired="recaptchaExpired"></vue-recaptcha>
        </div>
        <button type="button"
                style="margin-top: 1.5em"
                class="btn btn__send--inquiry"
                :class="{'btn-disabled': !isVerifyRecaptcha}"
                :disabled="!isVerifyRecaptcha"
                @click="onSendEmailInquiry">Send EMAIL</button>
      </form>

      <!--<div class="share-on" style="padding: 0 24px 24px;">-->
        <!--<span>Share on:</span>-->
        <!--<span><img src="@/assets/icons/icon_facebook.svg" width="24" height="24"></span>-->
        <!--<span><img src="@/assets/icons/icon_twitter.svg" width="24" height="24"></span>-->
        <!--<span><img src="@/assets/icons/icon_pinterest.svg" width="24" height="24"></span>-->
      <!--</div>-->
    </section>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import AlertMixin from '@/mixins/alert-mixin'

const RECAPTCHA_SITE_KEY = process.env.VUE_APP_RECAPTCHA_SITE_KEY || ''

export default {
  name: 'PropertyInquiry',
  mixins: [AlertMixin],
  components: { VueRecaptcha },
  props: {
    sale: {
      type: Object,
      default: () => {
        return {}
      }
    },
    inquiry: {
      type: Object,
      default: () => {
        return {
          propertyId: 0,
          saleId: 0,
          verifyRecaptcha: '',
          saleEmail: '',
          saleName: '',
          fullName: '',
          email: '',
          phone: '',
          message: ''
        }
      }
    }
  },
  model: {
    prop: 'inquiry',
    event: 'onInquiryChange'
  },
  data () {
    return {
      RECAPTCHA_SITE_KEY,
      isVerifyRecaptcha: false
    }
  },
  methods: {
    onSendEmailInquiry: function () {
      if (this._validateInquiryInput() === false) {
        return
      }

      this.$emit('onSendEmailInquiryButtonClick')
    },
    verifyRecaptcha: function (response) {
      this.inquiry.verifyRecaptcha = response
      this.isVerifyRecaptcha = true
    },
    recaptchaExpired: function () {
      this.isVerifyRecaptcha = false
    },
    _validateInquiryInput: function () {
      if (this.inquiry.fullName === '') {
        this.alertMessageError('Name is require')
        return false
      }

      if (this.inquiry.email === '') {
        this.alertMessageError('email is require')
        return false
      }

      if (this.inquiry.phone === '') {
        this.alertMessageError('phone is require')
        return false
      }

      return true
    },
    closeFormInquiry: function () {
      this.$emit('onCloseFormInquiryButtonClick')
    }
  }
}
</script>

<style scoped>
  .btn-disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }

  input[type='text'], input[type='email'], input[type='tel'], select, textarea {
    width: 100%;
    color: #484848;
    font-size: 13px;
    line-height: 1.25;
    padding: 12px 16px;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    -webkit-transition: all ease .15s;
    transition: all ease .15s;
    outline: none;
  }

  #form__inquiry {
    display: none;
  }
  #form__inquiry .btn__close--inquiry {
    display: none;
    position: absolute;
    top: 12px;
    right: 24px;
  }
  #form__inquiry section h4 {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 1em;
  }
  #form__inquiry .sale__detail {
    display: table;
    width: 100%;
    margin-bottom: 1rem;
  }
  #form__inquiry .sale__detail--image {
    width: 100%;
    text-align: center;
  }
  #form__inquiry .sale__detail--image img {
    overflow: hidden;
  }
  #form__inquiry .sale__detail--contact {
    display: inline-block;
    width: 100%;
    vertical-align: top;
  }

  .sale__name {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: .75em;
  }

  .sale__position {
    margin-bottom: 0.75em;
    opacity: 0.6;
    position: relative;
    top: -10px;
    font-size: 13px;
    font-weight: 400;
  }

  .sale__email, .sale__phone {
    font-size: 13px;
  }

  .sale__email > span, .sale__phone > span {
    vertical-align: middle;
    margin-right: 12px;
  }

  form label {
    font-size: .875rem;
    font-weight: 500;
    display: block;
    margin-bottom: 4px;
  }

  .form_inquiry>div {
    margin-bottom: 12px;
  }

  .btn__send--inquiry {
    width: 100%;
    margin-top: 4px;
    background-color: #F44336;
  }

  #form__inquiry label {
    color: #888888;
    font-size: 13px;
    font-weight: 500;
    display: block;
    margin-bottom: 4px;
    margin-top: 1em;
  }

  #form__inquiry section {
    width: 100%;
    max-width: 400px;
    background-color: #ffffff;
    padding: 28px 24px 32px;
  }
  .btn {
    font-size: 1rem;
    line-height: 1.25;
    color: #FFFFFF;
    border: 1px solid transparent;
    text-transform: uppercase;
    padding: 12px 24px;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    -webkit-box-shadow: 0 6px 12px -4px rgb(0 0 0 / 5%);
    box-shadow: 0 6px 12px -4px rgb(0 0 0 / 5%);
    -webkit-transition: -webkit-box-shadow ease .15s;
    transition: -webkit-box-shadow ease .15s;
    transition: box-shadow ease .15s;
    transition: box-shadow ease .15s, -webkit-box-shadow ease .15s;
  }
  .share-on > span {
    margin-left: 8px;
  }
  .share-on > span:first-child {
    vertical-align: super;
    margin-left: 0;
    font-size: .875rem;
    font-weight: 600;
    opacity: .8;
  }

  @media (max-width: 991px) {
    .show-inquiry #form__inquiry {
      display: block;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 1000;
      background: white;
      width: 100vw;
      height: 100vh;
      padding: 0px;
      overflow: scroll;
    }
    .show-inquiry #form__inquiry .btn__close--inquiry {
      display: block;
      cursor: pointer;
    }
    #form__inquiry > section > .share-on {
      margin-top: 20px;
    }

    #form__inquiry section {
      max-width: 100%;
    }
  }
  @media (min-width: 768px) {
    #form__inquiry section {
      margin: 40px auto 0;
    }
    .share-on {
      margin: 20px auto 0;
    }
  }
  @media (min-width: 992px) {
    #form__inquiry {
      display: block;
    }
    #form__inquiry section {
      margin-top: 0;
      -webkit-box-shadow: 0 2px 6px 0 rgba(125, 125, 125, 0.1);
      box-shadow: 0 2px 6px 0 rgba(125, 125, 125, 0.1);
    }
  }
  @media (min-width: 1200px) {
    #form__inquiry {
      display: block;
    }
    #form__inquiry section {
      margin-top: 0;
      -webkit-box-shadow: 0 2px 6px 0 rgb(125 125 125 / 10%);
      box-shadow: 0 2px 6px 0 rgb(125 125 125 / 10%);
    }
  }
</style>
