<template>
  <div id="photo-gallery" class="row property__detail">
    <div class="col-lg-12 col-xl-12">
      <!-- Photo -->
      <section id="photo" class="photo-section property__detail--topic position-relative" style="padding-top: 2rem; padding-bottom: 2rem; border-top: 1px solid #EEEEEE;" v-if="photoList.length > 0">
        <div class="primary">
          <splide :options="primaryOptions" ref="primary">
            <splide-slide v-for="(primaryPhotoUrl, primaryIndex) of photoUrlList" :key="`primary-${primaryIndex}`">
              <img :src="primaryPhotoUrl">
            </splide-slide>
          </splide>
        </div>
        <br>
        <div class="secondary">
          <splide :options="secondaryOptions" ref="secondary">
            <splide-slide v-for="(secondaryPhotoUrl, secondaryIndex) of photoUrlList" :key="`secondary-${secondaryIndex}`">
              <img :src="secondaryPhotoUrl">
            </splide-slide>
          </splide>
        </div>
      </section>

      <!-- 360 Tour -->
      <!--<section id="360_tour" class="360_tour-section property__detail&#45;&#45;topic position-relative" style="padding-top: 2rem; border-top: 1px solid #EEEEEE;" v-if="panoramaUrl !== ''">-->
        <!--<div class="anchor"></div>-->
        <!--<h3>360 TOUR</h3>-->
        <!--<a :href="panoramaUrl" target="_blank"></a>-->
      <!--</section>-->
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'

import '@splidejs/splide/dist/css/themes/splide-default.min.css'

export default {
  name: 'PropertyPhotoGallery',
  components: {
    Splide,
    SplideSlide
  },
  props: {
    panoramaUrl: {
      type: String
    },
    photoList: {
      type: Array,
      default: () => {
        return []
      }
    },
    floorPlanList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    photoUrlList: function () {
      return this.photoList.map(item => item.url)
    }
  },
  updated () {
    this.$refs.primary.sync(this.$refs.secondary.splide)
  },
  data () {
    return {
      photoIndex: null,
      primaryOptions: {
        type: 'slide',
        rewind: true,
        height: '30rem',
        cover: true,
        breakpoints: {
          height: '6rem'
        },
        pagination: false
      },
      secondaryOptions: {
        type: 'slide',
        rewind: true,
        gap: '1rem',
        height: 70,
        pagination: false,
        fixedWidth: 110,
        fixedHeight: 70,
        cover: true,
        focus: 'left',
        isNavigation: true
      }
    }
  }
}
</script>

<style scoped></style>
