import baseApi from './base-api'
import { PROPERTY_BOOKING_API_URL } from '../configs/service-url'

const all = async () => {
  try {
    const url = PROPERTY_BOOKING_API_URL.PROPERTY
    const result = await baseApi.get(url)

    return result
  } catch (err) {
    throw err
  }
}

const list = async (listType, propertyType = '') => {
  try {
    const url = `${PROPERTY_BOOKING_API_URL.PROPERTY}/list/${listType}/${propertyType}`
    const result = await baseApi.get(url)

    return result
  } catch (err) {
    throw err
  }
}

const detail = async (propertyId) => {
  try {
    const url = `${PROPERTY_BOOKING_API_URL.PROPERTY}/${propertyId}`
    const result = await baseApi.get(url)

    return result
  } catch (err) {
    throw err
  }
}

const search = async (data) => {
  try {
    const url = `${PROPERTY_BOOKING_API_URL.PROPERTY}/search`
    const result = await baseApi.post(url, data)

    return result
  } catch (err) {
    throw err
  }
}

export default {
  all,
  list,
  detail,
  search
}
