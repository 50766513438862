<template>
  <div>
    <section v-if="isShowContent()"
             id="overview" class="property__detail--topic position-relative" style="padding-top: 2rem; padding-bottom: 2rem;">
      <div class="attribute-icon-list">
        <div>
          <img :src='"@/assets/icons/"+getPropertyTypeIcon(property.type)+""'>
          <div>{{ PROPERTY_TYPE_TITLE[property.type] || 'N/A' }}</div>
        </div>
        <div v-if="attributeSleep !== ''">
          <img src="@/assets/icons/bedrooms.svg">
          <div> {{attributeSleep}}</div>
        </div>
        <div v-if="attributeBath !== ''">
          <img src="@/assets/icons/bathrooms.svg">
          <div> {{attributeBath}}</div>
        </div>
        <div v-if="property.type === PROPERTY_TYPE.VILLAS_AND_HOUSES || property.type === PROPERTY_TYPE.LAND">
          <div v-if="attributeLandArea !== ''">
            <img src="@/assets/icons/area.svg">
            <div> {{attributeLandArea}}</div>
          </div>
        </div>
        <div v-if="property.type === PROPERTY_TYPE.APARTMENTS_AND_CONDOS">
          <div v-if="attributeLivingArea !== ''">
            <img src="@/assets/icons/area.svg">
            <div> {{attributeLivingArea}}</div>
          </div>
        </div>
        <div v-if="property.type === PROPERTY_TYPE.COMMERCIAL">
          <div v-if="attributeBuildingArea !== ''">
            <img src="@/assets/icons/area.svg">
            <div> {{attributeBuildingArea}}</div>
          </div>
        </div>
        <div v-if="attributeParking !== ''">
          <img src="@/assets/icons/garage.svg">
          <div> {{attributeParking}}</div>
        </div>
      </div>
    </section>
    <section class="detail_features-section property__detail--topic position-relative" style="padding-top: 2rem; padding-bottom: 2rem;">
      <div>
        <div class="anchor"></div>
        <h4 style="margin-bottom: 1em">DETAIL & FEATURE</h4>
        <div class="detail-feature-list">
          <div class="detail-feature">
            <label>Property Type: </label>
            <span>{{ PROPERTY_TYPE_TITLE[property.type] || 'N/A' }}</span>
          </div>
          <div class="detail-feature">
            <label>Condition: </label>
            <span>{{ PROPERTY_STATUS_TEXT[property.status] || 'N/A' }}</span>
          </div>
          <div v-for="(detailAndFeature, key) of detailAndFeatureList" :key="key"
               v-show="detailAndFeature.value !== ''"
               class="detail-feature">
            <label>{{ detailAndFeature.label }}: </label>
            <span>{{ detailAndFeature.value }}</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  LOCATION,
  PROPERTY_TYPE,
  PROPERTY_TYPE_TITLE,
  PROPERTY_STATUS_TEXT
} from '@/configs/constants'

export default {
  name: 'Overview',
  props: {
    attributeSleep: {
      type: String,
      default: ''
    },
    attributeBath: {
      type: String,
      default: ''
    },
    attributeParking: {
      type: String,
      default: ''
    },
    attributeLivingArea: {
      type: String,
      default: ''
    },
    attributeLandArea: {
      type: String,
      default: ''
    },
    attributeBuildingArea: {
      type: String,
      default: ''
    },
    attributeOwnership: {
      type: String,
      default: ''
    },
    property: {
      type: Object,
      default: () => {
        return {}
      }
    },
    propertyDetail: {
      type: Object,
      default: () => {
        return {}
      }
    },
    detailAndFeatureList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      PROPERTY_STATUS_TEXT,
      PROPERTY_TYPE_TITLE,
      PROPERTY_TYPE
    }
  },
  methods: {
    isShowContent: function () {
      return (this.attributeSleep !== '' || this.attributeBath !== '' || this.attributeParking !== '' || this.attributeLivingArea !== '' || this.attributeOwnership !== '')
    },
    getPropertyTypeIcon: function (type) {
      switch (type) {
        case PROPERTY_TYPE.VILLAS_AND_HOUSES:
          return 'icon_house.svg'
        case PROPERTY_TYPE.APARTMENTS_AND_CONDOS:
          return 'icon_condo.svg'
        case PROPERTY_TYPE.LAND:
          return 'icon_land.svg'
        case PROPERTY_TYPE.COMMERCIAL:
          return 'icon_house.svg'
        default:
          return 'icon_house.svg'
      }
    },
    getLocationTitle: function (locationId) {
      const result = LOCATION.find(item => item.id === locationId)

      if (result !== undefined) {
        return `${result.title}, ${result.district}, ${result.province}`
      }

      return 'N/A'
    }
  }
}
</script>

<style scoped>
  .overview {
    margin-top: 40px;
  }

  .overview > div {
    display: flex; margin-top: 20px
  }

  .overview > div > div > div:nth-child(1) {
    opacity: 0.5;
    font-weight: bold;
  }
  .overview__type {
    padding-right: 40px;
  }

  .overview__beds {
    padding-right: 40px;
  }

  .overview__lists {
    list-style: none;
    margin-top: 2.5em;
    column-count: 2;
    column-gap: 16px;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  .overview__lists>li {
    margin-bottom: 1.5em;
  }

  .overview__lists>li:nth-child(2n+1) {
    padding-right: 24px;
  }

  .overview__lists>li:nth-child(2n) {
    /*padding-left: 12px;*/
  }

  .overview__lists label {
    font-size: .875rem;
    font-weight: 600;
    display: block;
    opacity: .5;
  }

  .list-horizontal ul {
    width: 100%;
  }

  .detail-feature-list {
    font-size: 15px;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .detail-feature {
    display: flex;
    margin: 5px 0;
  }

  .detail-feature label {
    font-weight: 600;
    opacity: .5;
    width: 50%;
  }

  .detail-feature span{
    width: 50%;
  }

  .attribute-icon-list {
    display: flex;
    justify-content: space-between;
    font-size: 13px
  }

  .attribute-icon-list img {
    height: 25px;
    margin-right: 8px;
    max-width: 25px;
    opacity: .8;
  }

  .attribute-icon-list div {
    display: flex;
    justify-content: space-between;
    align-self: center;
  }

  .attribute-icon-list > div > div {
    align-self: center;
  }

  @media (max-width: 1200px) {
    .attribute-icon-list > div {
      align-self: auto;
      flex-direction: column;
      align-items: center;
    }

    .attribute-icon-list img {
      margin-right: 0;
    }
  }

  @media (max-width: 768px) {
    .detail-feature-list {
      font-size: 15px;
      display: grid;
      grid-template-columns: 100%;
    }
  }
</style>
