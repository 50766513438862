<template>
  <div class="row" v-if="relatedPropertyList.length > 0">
    <section id="similar-property" class="col-lg-12">
      <h3>More properties you may like</h3>
      <div class="list-horizontal">
        <div style="height: 400px">
          <ul>
            <li v-for="(relatedProperty, index) of relatedPropertyList" :key="index" @click="viewPropertyDetail(relatedProperty.id)" style="cursor: pointer">
              <img class="cover property-image" :src="getImageUrl(relatedProperty.photoList)" width="100%">
              <div style="padding: 15px">
                <div class="name">{{relatedProperty.name}}</div>
                <div class="location">
                  <img src="@/assets/icons/icon_location.svg">
                  {{getLocationTitle(relatedProperty.location)}}
                </div>
                <div style="margin-top: 10px">
                  <div class="price" v-if="relatedProperty.isSale">
                    <span class="currency">THB </span>
                    {{numberFormat(relatedProperty.salePrice)}}
                  </div>
                  <div class="price" v-if="relatedProperty.isRent">
                    <span class="currency">THB </span>
                    {{numberFormat(relatedProperty.rentPrice)}} /{{ RENT_TYPE_TITLE[relatedProperty.rentType] }}
                  </div>
                </div>
                <div class="overview">
                <span class="property-type">
                  <label>
                    <img :src='"@/assets/icons/"+getPropertyTypeIcon(relatedProperty.typeId)+""' height="20" style="position: relative; top: 1px; margin-right: 4px;">
                  </label>
                  <div>
                    {{ PROPERTY_TYPE_TITLE[relatedProperty.type] || 'N/A' }}
                  </div>
                </span>

                  <span class="beds" v-if="relatedProperty.bed > 0">
                  <label><img src="@/assets/icons/icon_bed.svg" width="24"></label>
                  <div>
                    {{relatedProperty.bed}}
                  </div>
                </span>

                  <span class="baths" v-if="relatedProperty.bath > 0">
                  <label><img src="@/assets/icons/icon_bath.svg" width="24"></label>
                  <div>
                    {{relatedProperty.bath}}
                  </div>
                </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { numberFormat, getImageUrl } from '@/helpers'
import {
  LOCATION,
  PROPERTY_TYPE,
  PROPERTY_TYPE_TITLE,
  PROPERTY_STATUS_TEXT,
  RENT_TYPE_TITLE
} from '@/configs/constants'

export default {
  name: 'PropertyRelatedList',
  props: {
    relatedPropertyList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      numberFormat,
      getImageUrl,
      PROPERTY_STATUS_TEXT,
      PROPERTY_TYPE_TITLE,
      RENT_TYPE_TITLE
    }
  },
  methods: {
    getPropertyTypeIcon: function (type) {
      switch (type) {
        case PROPERTY_TYPE.VILLAS_AND_HOUSES:
          return 'icon_house.svg'
        default:
          return 'icon_condo.svg'
      }
    },
    getLocationTitle: function (locationId) {
      const result = LOCATION.find(item => item.id === locationId)

      if (result !== undefined) {
        return `${result.title}, ${result.district}, ${result.province}`
      }

      return 'N/A'
    },
    viewPropertyDetail: function (propertyId) {
      this.$emit('onRelatedPropertyClick', propertyId)
    }
  }
}
</script>

<style scoped>
  .list-horizontal ul {
    width: 100%;
  }
  .property-image {
    height: 200px;
    border-radius: 7px 7px 0 0;
  }

  .name {
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
