<template>
  <div class="container" style="margin-bottom: 40px;">
    <div style="font-size: 35px" v-if="listType === 'buy'">BUY</div>
    <div style="font-size: 35px" v-else>RENT</div>
    <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
      <div style="text-align: left">{{propertyList.length}} properties found.</div>
      <div style="text-align: right; margin-bottom: 20px" class="d-none d-md-block">
        <div class="filter_view-by">
          <label class="btn-view-by">
            <input type="radio" name="view-by" id="row" v-model="viewBy" :value="VIEW_BY.ROW">
            <svg id="icon_list-view" width="24px" height="24px" viewBox="0 0 64 64" fill="#888888" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g>
                <rect id="Rectangle" x="0" y="1" width="16" height="16"></rect>
                <rect id="Rectangle" x="23" y="1" width="41" height="16"></rect>
                <rect id="Rectangle" x="0" y="24" width="16" height="16"></rect>
                <rect id="Rectangle" x="23" y="24" width="41" height="16"></rect>
                <rect id="Rectangle" x="0" y="47" width="16" height="16"></rect>
                <rect id="Rectangle" x="23" y="47" width="41" height="16"></rect>
              </g>
            </svg>
          </label>
          <label class="btn-view-by">
            <input type="radio" name="view-by" id="column" v-model="viewBy" :value="VIEW_BY.COLUMN">
            <svg id="icon_grid-view" width="24px" height="24px" viewBox="0 0 64 64" fill="#888888" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g>
                <rect id="Rectangle" x="0" y="0" width="28" height="28"></rect>
                <rect id="Rectangle" x="36" y="0" width="28" height="28"></rect>
                <rect id="Rectangle" x="0" y="36" width="28" height="28"></rect>
                <rect id="Rectangle" x="36" y="36" width="28" height="28"></rect>
              </g>
            </svg>
          </label>
        </div>
      </div>
    </div>
    <div id="properties-container" class="property-items" :class="{'view-row': viewBy === VIEW_BY.ROW, 'view-column': viewBy === VIEW_BY.COLUMN}">
      <div v-for="(property, index) of propertyList" :key="index" class="item">
        <div class="property_image">
          <img class="cover" alt="" style="width: 100%; cursor: pointer"
               @click="viewPropertyDetail(property)"
               :src="getImageUrl(property.photoList)">
        </div>
        <div class="property_status">
          <div v-if="property.status !== PROPERTY_STATUS.NO_STATUS"
               class="building-type" :class="`building-type-${property.status}`">
            {{PROPERTY_STATUS_TEXT[property.status]}}
          </div>
          <div class="marketing exclusive-discount" v-if="property.marketingWord !== ''">
            <span v-html="property.marketingWord"></span>
          </div>
        </div>
        <div class="property_content">
          <div>
            <div>
              <div style="margin-bottom: 10px">
                <div class="property_name" @click="viewPropertyDetail(property)">{{property.name}}</div>
                <div class="property_location">
                  <img src="@/assets/icons/icon_location.svg"
                       style="width: 18px;position: relative; top: 1px; margin-right: 4px; opacity: .8;">
                  {{ getLocationTitle(property.location) }}
                </div>
              </div>
              <div class="property_price" style="margin-bottom: 15px" v-if="listType === LIST_TYPE.BUY">
                {{numberFormat(property.salePrice)}} THB
              </div>
              <div class="property_price" style="margin-bottom: 15px" v-if="listType === LIST_TYPE.RENT">
                {{numberFormat(property.rentPrice)}} THB /{{ RENT_TYPE_TITLE[property.rentType] }}
              </div>
            </div>
            <div>
              <div class="overview" style="margin-bottom: 10px">
                <div class="property-type">
                  <img :src='"@/assets/icons/"+getPropertyTypeIcon(property.type)+""'
                       style="height: 18px; margin-right: 4px; opacity: 0.8">
                  <div>{{ PROPERTY_TYPE_TITLE[property.type] || 'N/A' }}</div>
                </div>
                <div class="attr-icon-list">
                  <div class="attr-icon" v-if="property.sleep !== ''">
                    <img alt="attr-icon" src="@/assets/icons/bathrooms.svg">
                    <div>{{property.sleep}}</div>
                  </div>
                  <div class="attr-icon" v-if="property.bath !== ''">
                    <img alt="attr-icon" src="@/assets/icons/bedrooms.svg">
                    <div>{{property.bath}}</div>
                  </div>
                  <div v-if="(property.type === PROPERTY_TYPE.VILLAS_AND_HOUSES || property.type === PROPERTY_TYPE.LAND) && property.landArea !== ''"
                       class="attr-icon">
                    <img alt="attr-icon" src="@/assets/icons/area.svg">
                    <div>{{property.landArea}}</div>
                  </div>
                  <div class="attr-icon" v-if="property.type === PROPERTY_TYPE.APARTMENTS_AND_CONDOS && property.livingArea !== ''">
                    <img alt="attr-icon" src="@/assets/icons/area.svg">
                    <div>{{property.livingArea}}</div>
                  </div>
                  <div class="attr-icon" v-if="property.type === PROPERTY_TYPE.COMMERCIAL && property.buildingArea !== ''">
                    <img alt="attr-icon" src="@/assets/icons/area.svg">
                    <div>{{property.buildingArea}}</div>
                  </div>
                  <div class="attr-icon" v-if="property.parking !== ''">
                    <img alt="attr-icon" src="@/assets/icons/garage.svg">
                    <div>{{property.parking}}</div>
                  </div>
                </div>
              </div>
              <ul class="property_overview" style="list-style: none; font-size: 15px">
                <li v-if="property.view !== ''">
                  <label style="opacity: .75;">View: </label>
                  {{property.view}}
                </li>
                <li v-if="property.swimmingPool !== ''">
                  <label style="opacity: .75;">Swimming Pool: </label>
                  {{property.swimmingPool}}
                </li>
                <li v-if="(property.type === PROPERTY_TYPE.VILLAS_AND_HOUSES || property.type === PROPERTY_TYPE.LAND) && property.landArea !== ''">
                  <label style="opacity: .75;">Land Area: </label>
                  {{property.landArea}}
                </li>
                <li v-if="property.type === PROPERTY_TYPE.APARTMENTS_AND_CONDOS && property.livingArea !== ''">
                  <label style="opacity: .75;">Living Area: </label>
                  {{property.livingArea}}
                </li>
                <li v-if="property.type === PROPERTY_TYPE.COMMERCIAL && property.buildingArea !== ''">
                  <label style="opacity: .75;">Building Area: </label>
                  {{property.buildingArea}}
                </li>
                <li v-if="property.ownership !== ''">
                  <label style="opacity: .75;">Ownership: </label>
                  {{property.ownership}}
                </li>
              </ul>
            </div>
          </div>
          <div class="btn-content">
            <a class="btn__more-detail" @click="viewPropertyDetail(property)">
              View Details
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PROPERTY_STATUS,
  PROPERTY_TYPE,
  PROPERTY_TYPE_TITLE,
  PROPERTY_STATUS_TEXT,
  LOCATION,
  HEADER_STYLE,
  RENT_TYPE_TITLE,
  LIST_TYPE
} from '@/configs/constants'

import { numberFormat, getImageUrl } from '@/helpers'

export default {
  name: 'propertyList',
  data () {
    return {
      isLoading: false,
      numberFormat,
      getImageUrl,
      PROPERTY_STATUS,
      PROPERTY_STATUS_TEXT,
      PROPERTY_TYPE_TITLE,
      RENT_TYPE_TITLE,
      LIST_TYPE,
      PROPERTY_TYPE,
      VIEW_BY: {
        ROW: 1,
        COLUMN: 2
      },
      viewBy: 1,
      header: {
        style: HEADER_STYLE.HALF,
        imgPath: ''
      }
    }
  },
  props: {
    propertyList: {
      type: Array,
      default: () => {
        return []
      }
    },
    listType: {
      type: String,
      default: LIST_TYPE.BUY
    }
  },
  methods: {
    getPropertyTypeIcon: function (type) {
      switch (type) {
        case PROPERTY_TYPE.VILLAS_AND_HOUSES:
          return 'icon_house.svg'
        case PROPERTY_TYPE.APARTMENTS_AND_CONDOS:
          return 'icon_condo.svg'
        case PROPERTY_TYPE.LAND:
          return 'icon_land.svg'
        case PROPERTY_TYPE.COMMERCIAL:
          return 'icon_house.svg'
        default:
          return 'icon_house.svg'
      }
    },
    viewPropertyDetail: function (property) {
      return this.$router.push({
        name: 'PropertyDetail',
        params: {
          propertyId: property.id
        }
      })
    },
    getLocationTitle: function (locationId) {
      const result = LOCATION.find(item => item.id === locationId)

      if (result !== undefined) {
        return result.title
      }

      return 'N/A'
    }
  }
}
</script>

<style scoped>
  .font-bold {
    font-weight: bold;
  }

  .property-items {
    text-align: left;
    width: 100%;
  }

  .property_image > img{
    max-height: 260px;
  }

  .view-row {
    display: table;
  }

  .view-row .property_image > img {
    border-radius: 8px;
  }

  .view-column .property_image > img {
    border-radius: 8px 8px 0 0;
    height: 220px;
  }

  .view-row .btn-view-detail {
    text-align: right;
  }

  .view-column {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
  }
  .view-column .btn-content {
    text-align: right;
  }

  .view-column .item {
    flex-basis: 30%;
    border: 1px solid #d8d8d8;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%);
    border-radius: 10px;
  }

  .view-column .item .item-detail {
    width: 100% !important;
  }

  .view-column .property_name {
    max-height: 36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .view-column .property_detail {
    height: 31px;
  }

  .view-column .property_content {
    padding: 10px;
  }
  .view-column .property_content > div > div:first-child {
    height: 105px;
  }

  .btn__more-detail {
    font-size: .875rem;
    color: #455A64;
    display: inline-block;
    border: 1px solid;
    border-radius: 4px;
    padding: 7px 10px;
    cursor: pointer;
    text-align: center;
  }

  .property_price {
    margin-top: 0;
    color: #455A64;
  }

  .property_name {
    cursor: pointer;
  }

  .property_name:hover {
    text-decoration: underline;
  }

  .item {
    position: relative;
  }
  .view-row .item {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }

  .view-column .item {
    padding-bottom: 0;
    margin-bottom: 15px;
    max-width: 375px;
    min-width: 305px;
  }

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .building-type {
    color: #FFFFFF;
    background-color: rgba(0, 0, 0, 0.8);
    text-transform: uppercase;
    padding: 2px 4px;
    border-radius: 4px;
    margin-right: 4px;
    margin-bottom: 2px;
    float: left;
  }

  .marketing {
    color: #FFFFFF;
    background-color: rgba(255, 0, 0, 0.8);
    padding: 2px 4px;
    border-radius: 4px;
    float: left;
    text-transform: uppercase;
  }

  .property_status > div {
    font-size: 13px !important;
    top: 0;
    left: 0;
    padding: 4px;
    margin: 4px;
  }

  .property-type {
    display: flex !important;
    align-self: center;
    color: #484848;
    font-weight: 500;
  }

  .attr-icon-list {
    display: flex;
    height: 40px;
  }

  .attr-icon {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #484848;
    font-weight: 500;
    margin-right: 25px;
  }

  .attr-icon img {
    height: 18px;
    margin-right: 5px;
    opacity: .8;
  }

  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }

    .view-column {
      display: grid;
      grid-template-columns: auto;
      grid-gap: 20px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }

    .view-column {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 20px;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }

    .view-column {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 20px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1300px;
    }

    .view-column {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 20px;
    }
  }
</style>
