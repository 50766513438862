<template>
  <div>
    <section class="overview-section property__detail--topic position-relative" style="padding-top: 2rem; padding-bottom: 2rem;">
      <div>
        <div class="anchor"></div>
        <h4 style="margin-bottom: 0; cursor: pointer" @click="isOpenDetail = !isOpenDetail">
          PROJECT OVERVIEW & FEATURES
          <img src="@/assets/icons/icon_arrow-down.svg"
               :class="{'close-detail': isOpenDetail}"
               class="btn-open-detail">
        </h4>
        <div v-show="isOpenDetail" style="margin-top: 1em" class="detail-feature-list">
          <div v-for="(projectOverview, key) of projectOverviewList" :key="key"
               v-show="projectOverview.value !== ''"
               class="detail-feature">
            <label>{{ projectOverview.label }}: </label>
            <span>{{ projectOverview.value }}</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  LOCATION,
  PROPERTY_TYPE,
  PROPERTY_TYPE_TITLE,
  PROPERTY_STATUS_TEXT
} from '@/configs/constants'

export default {
  name: 'PropertyOverview',
  props: {
    projectName: {
      type: String
    },
    projectOverviewList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      PROPERTY_STATUS_TEXT,
      PROPERTY_TYPE_TITLE,
      isOpenDetail: false
    }
  },
  methods: {
    getPropertyTypeIcon: function (type) {
      switch (type) {
        case PROPERTY_TYPE.VILLAS_AND_HOUSES:
          return 'icon_house.svg'
        default:
          return 'icon_condo.svg'
      }
    },
    getLocationTitle: function (locationId) {
      const result = LOCATION.find(item => item.id === locationId)

      if (result !== undefined) {
        return `${result.title}, ${result.district}, ${result.province}`
      }

      return 'N/A'
    }
  }
}
</script>

<style scoped>
  .overview {
    margin-top: 40px;
  }

  .overview > div {
    display: flex; margin-top: 20px
  }

  .overview > div > div > div:nth-child(1) {
    opacity: 0.5;
    font-weight: bold;
  }
  .overview__type {
    padding-right: 40px;
  }

  .overview__beds {
    padding-right: 40px;
  }

  .overview__lists {
    list-style: none;
    margin-top: 2.5em;
    column-count: 2;
    column-gap: 16px;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  .overview__lists>li {
    margin-bottom: 1.5em;
  }

  .overview__lists>li:nth-child(2n+1) {
    padding-right: 24px;
  }

  .overview__lists>li:nth-child(2n) {
    /*padding-left: 12px;*/
  }

  .overview__lists label {
    font-size: .875rem;
    font-weight: 600;
    display: block;
    opacity: .5;
  }

  .list-horizontal ul {
    width: 100%;
  }

  .detail-feature-list {
    font-size: 15px;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .detail-feature {
    display: flex;
    margin: 5px 0;
  }

  .detail-feature label {
    font-weight: 600;
    opacity: .5;
    width: 50%;
  }

  .detail-feature span{
    width: 50%;
  }

  .btn-open-detail {
    float: right;
    cursor: pointer;
    width: 16px;
    margin-top: 7px;
  }

  .close-detail {
    transform: rotate(180deg);
  }

  @media (max-width: 768px) {
    .detail-feature-list {
      font-size: 15px;
      display: grid;
      grid-template-columns: 100%;
    }
  }
</style>
