<template>
  <main-template :header="header" :is-loading="isLoading">
    <property-list :property-list="propertyList" :list-type="listType" />
  </main-template>
</template>

<script>
import ConfigApi from '@/api/config-api'
import MainTemplate from '@/templates/MainTemplate'
import PropertyList from '@/views/Property/components/PropertyList'
import PropertyApi from '@/api/property-api'
import {
  LIST_TYPE,
  COVER_IMG_TYPE
} from '@/configs/constants'
import { get } from 'lodash'
import { delay } from '@/helpers'

export default {
  name: 'List',
  components: {
    MainTemplate,
    PropertyList
  },
  data () {
    return {
      isLoading: false,
      propertyList: [],
      header: {
        className: '',
        style: '',
        imgPath: '',
        isShow: true
      },
      listType: LIST_TYPE.BUY
    }
  },
  computed: {
    prefetchItems () {
      return this.$store.state.prefetchItems
    }
  },
  async serverPrefetch () {
    await this.preparePrefetchItems(true)
  },
  watch: {
    async $route () {
      this.isLoading = true
      await this.preparePrefetchItems(false)
      await delay(300)
      this.isLoading = false
    }
  },
  async created () {
    try {
      this.isLoading = true
      await this.preparePrefetchItems(false)
      await delay(300)
      this.isLoading = false
    } catch (err) {
      // console.log(err)
    }
  },
  methods: {
    async preparePrefetchItems (isServerPrefetch) {
      const listType = get(this.$route, 'params.listType', LIST_TYPE.BUY).toLowerCase()
      const propertyType = get(this.$route, 'params.propertyType', '').toLowerCase()
      const coverImgType = (listType === LIST_TYPE.BUY) ? COVER_IMG_TYPE.BUY : COVER_IMG_TYPE.RENTAL

      let prefetchItems = this.prefetchItems
      if (prefetchItems === null) {
        const propertyList = await PropertyApi.list(listType, propertyType)
        const coverImg = await ConfigApi.coverImgUrl(coverImgType)

        const header = {
          className: '',
          style: '',
          imgPath: coverImg.url,
          isShow: true,
          resizeLogo: true
        }

        prefetchItems = {
          listType,
          header,
          propertyList
        }
      }

      if (isServerPrefetch) {
        this.$store.dispatch('initPrefetchItems', prefetchItems)
      } else {
        Object.assign(this, prefetchItems)
        this.$store.dispatch('clearPrefetchItems')
      }
    }
  }
}
</script>

<style scoped>
</style>
