<template>
  <main-template :header="header" :is-loading="isLoading">
    <property-list :property-list="propertyList" />
  </main-template>
</template>

<script>
import MainTemplate from '@/templates/MainTemplate'
import PropertyList from '@/views/Property/components/PropertyList'
import PropertyApi from '@/api/property-api'
import ConfigApi from '@/api/config-api'
import {
  LIST_TYPE,
  COVER_IMG_TYPE
} from '@/configs/constants'
import { delay } from '@/helpers'

export default {
  name: 'SearchProperty',
  components: {
    MainTemplate,
    PropertyList
  },
  props: {
    listType: {
      type: String,
      default: LIST_TYPE.BUY
    },
    selectPropertyTypeList: {
      type: Array,
      default: () => {
        return []
      }
    },
    selectedLocation: {
      type: Object,
      default: () => {
        return {
          areaList: [],
          locationList: []
        }
      }
    }
  },
  data () {
    return {
      page: 1,
      isLoading: false,
      propertyList: [],
      header: {
        className: '',
        style: '',
        imgPath: '',
        isShow: true
      }
    }
  },
  computed: {
    prefetchItems () {
      return this.$store.state.prefetchItems
    }
  },
  async serverPrefetch () {
    await this.preparePrefetchItems(true)
  },
  watch: {
    async $route () {
      this.isLoading = true
      await this.preparePrefetchItems(false)
      await delay(300)
      this.isLoading = false
    }
  },
  async created () {
    try {
      this.isLoading = true
      await this.preparePrefetchItems(false)
      await delay(300)
      this.isLoading = false
    } catch (err) {
      // console.log(err)
    }
  },
  methods: {
    async preparePrefetchItems (isServerPrefetch) {
      let prefetchItems = this.prefetchItems
      const condition = {
        listType: this.listType,
        propertyTypeList: this.selectPropertyTypeList,
        locationList: this.selectedLocation.locationList,
        areaList: this.selectedLocation.areaList,
        page: this.page
      }

      const coverImgType = (this.listType === LIST_TYPE.BUY) ? COVER_IMG_TYPE.BUY : COVER_IMG_TYPE.RENTAL

      if (prefetchItems === null) {
        const propertyList = await PropertyApi.search(condition)
        const coverImg = await ConfigApi.coverImgUrl(coverImgType)

        const header = {
          className: '',
          style: '',
          imgPath: coverImg.url,
          isShow: true,
          resizeLogo: true
        }

        prefetchItems = {
          header,
          propertyList
        }
      }

      if (isServerPrefetch) {
        this.$store.dispatch('initPrefetchItems', prefetchItems)
      } else {
        Object.assign(this, prefetchItems)
        this.$store.dispatch('clearPrefetchItems')
      }
    }
  }
}
</script>

<style scoped>

</style>
