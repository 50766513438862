<template>
  <main-template :header="header" :footer="footer" :is-loading="isLoading">
    <div id="scroll-nav">
      <!--<div class="container-fluid" style="padding: 0;">-->
        <!--<section>-->
          <!--<button type="button" id="btn__photo"-->
                  <!--@click="navButton('photo-section')" v-if="photoList.length > 0">-->
            <!--Photo Gallery-->
          <!--</button>-->
          <!--<button type="button" id="btn__floor_plan"-->
                  <!--@click="navButton('floor_plan-section')"-->
                  <!--v-if="floorPlanList.length > 0">-->
            <!--Floor Plan-->
          <!--</button>-->
          <!--<button type="button" id="btn__360_tour"-->
                  <!--@click="navButton('360_tour-section')"-->
                  <!--v-if="propertyDetail.panoramaUrl !== ''">-->
            <!--360° Tour-->
          <!--</button>-->
          <!--<button type="button" id="btn__detail_features"-->
                  <!--@click="navButton('detail_features-section')"-->
                  <!--v-if="propertyDetail.featureDetail !== ''">-->
            <!--Detail & Features-->
          <!--</button>-->
          <!--<button type="button" id="btn__description"-->
                  <!--@click="navButton('description-section')"-->
                  <!--v-if="propertyDetail.description !== ''">-->
            <!--Description-->
          <!--</button>-->
          <!--<button type="button" id="btn__property_features"-->
                  <!--@click="navButton('property_features-section')"-->
                  <!--v-if="propertyDetail.featureDetail !== ''">-->
            <!--Property Detail-->
          <!--</button>-->
          <!--<button type="button" id="btn__community_features"-->
                  <!--@click="navButton('community_features-section')"-->
                  <!--v-if="propertyDetail.communityDetail !== ''">-->
            <!--Community Features-->
          <!--</button>-->
          <!--<button type="button" id="btn__location"-->
                  <!--@click="navButton('location-section')"-->
                  <!--v-if="propertyDetail.locationDescription !== ''">-->
            <!--Location-->
          <!--</button>-->
          <!--<button type="button" id="btn__map"-->
                  <!--@click="navButton('map-section')"-->
                  <!--v-if="propertyDetail.latitude !== '' && propertyDetail.longitude !== ''">-->
            <!--Map-->
          <!--</button>-->
          <!--<button type="button" id="btn__overview"-->
                  <!--@click="navButton('overview-section')">-->
            <!--Overview-->
          <!--</button>-->
        <!--</section>-->
      <!--</div>-->
      <button type="button"
              @click="navButton('form__inquiry-section')"
              class="btn--make-inquiry">
        Make an inquiry
      </button>
    </div>
    <div class="container" :class="{'show-inquiry': isDisplayInquiry}">
      <div class="row property__detail--info property__detail">
        <div class="col-lg-9 col-xl-9">
          <section>
            <div class="row">
              <div class="col-lg-8">
                <h2 class="property__detail--name">{{property.name}}</h2>
                <small @click="navButton('map-section')" style="cursor: pointer; text-decoration: underline;"><img src="@/assets/icons/icon_location.svg">{{getLocationTitle(propertyDetail.location)}}</small>
              </div>
              <div class="col-lg-4 property__detail--price">
                <div v-if="property.isSale">
                  <span class="sale-rent-label">Sale: </span>{{numberFormat(property.salePrice)}} THB
                </div>
                <div v-if="property.isRent">
                  <span class="sale-rent-label">Rent: </span>{{numberFormat(property.rentPrice)}} THB /{{ RENT_TYPE_TITLE[property.rentType] }}
                </div>
              </div>
            </div>
          </section>

          <property-photo-gallery
            :panorama-url="propertyDetail.panoramaUrl"
            :photo-list="photoList"
            :floor-plan-list="floorPlanList" />

          <property-overview
            :property="property"
            :property-detail="propertyDetail"
            :attribute-sleep="attributeSleep"
            :attribute-bath="attributeBath"
            :attribute-parking="attributeParking"
            :attribute-living-area="attributeLivingArea"
            :attribute-land-area="attributeLandArea"
            :attribute-building-area="attributeBuildingArea"
            :attribute-ownership="attributeOwnership"
            :detail-and-feature-list="detailAndFeatureList" />

          <div>
            <section id="description" class="property__detail--topic position-relative" v-if="propertyDetail.description !== ''">
              <div class="description-section anchor"></div>
              <h4 style="margin-bottom: 0; cursor: pointer" @click="isOpenDetail.description = !isOpenDetail.description">
                DESCRIPTION
                <img src="@/assets/icons/icon_arrow-down.svg"
                     :class="{'close-detail': isOpenDetail.description}"
                     class="btn-open-detail">
              </h4>
              <div v-html="propertyDetail.description" v-show="isOpenDetail.description" style="margin-top: 1em;"></div>
            </section>

            <section id="promotion-detail" class="property__detail--topic position-relative" v-if="propertyDetail.promotionDetail !== ''">
              <div class="description-section anchor"></div>
              <h4 style="margin-bottom: 0; cursor: pointer" @click="isOpenDetail.promotion = !isOpenDetail.promotion">
                PROMOTION
                <img src="@/assets/icons/icon_arrow-down.svg"
                     :class="{'close-detail': isOpenDetail.promotion}"
                     class="btn-open-detail">
              </h4>
              <div v-html="propertyDetail.promotionDetail" v-show="isOpenDetail.promotion" style="margin-top: 1em;"></div>
            </section>

            <section id="property_features" class="property__detail--topic position-relative" v-if="propertyDetail.featureDetail !== ''">
              <div class="property_features-section anchor"></div>
              <h4 style="margin-bottom: 0; cursor: pointer" @click="isOpenDetail.propertyDetail = !isOpenDetail.propertyDetail">
                ADDITIONAL FEATURES
                <img src="@/assets/icons/icon_arrow-down.svg"
                     :class="{'close-detail': isOpenDetail.propertyDetail}"
                     class="btn-open-detail">
              </h4>
              <div class="property_features" v-html="propertyDetail.featureDetail" v-show="isOpenDetail.propertyDetail" style="margin-top: 1em"></div>
            </section>

            <!-- Floor Plan -->
            <section v-if="floorPlanList.length > 0" id="floor_plan"
                     class="floor_plan-section property__detail--topic position-relative" style="padding-top: 2rem; padding-bottom: 2rem; border-top: 1px solid #EEEEEE;">
              <div class="anchor"></div>
              <h4 style="margin-bottom: 0; cursor: pointer" @click="isOpenDetail.floorPlan = !isOpenDetail.floorPlan">
                FLOOR PLAN
                <img src="@/assets/icons/icon_arrow-down.svg"
                     :class="{'close-detail': isOpenDetail.floorPlan}"
                     class="btn-open-detail">
              </h4>
              <div v-show="isOpenDetail.floorPlan" style="margin-top: 1em;">
                <LightGallery
                  :images="floorPlanUrlList"
                  :index="floorPlanIndex"
                  :disable-scroll="true"
                  @close="floorPlanIndex = null"
                />
                <div class="lightgallery">
                <span
                  v-for="(floorPlanUrl, thumbIndex) of floorPlanUrlList" :key="thumbIndex"
                  @click="floorPlanIndex = thumbIndex">
                  <img :src="floorPlanUrl" class="cover property-gallery">
                </span>
                </div>
              </div>
            </section>

            <section id="location" class="property__detail--topic position-relative" v-if="propertyDetail.locationDescription !== ''">
              <div class="location-section anchor"></div>
              <h4 style="margin-bottom: 0; cursor: pointer" @click="isOpenDetail.location = !isOpenDetail.location">
                LOCATION
                <img src="@/assets/icons/icon_arrow-down.svg"
                     :class="{'close-detail': isOpenDetail.location}"
                     class="btn-open-detail">
              </h4>
              <div v-html="propertyDetail.locationDescription" v-show="isOpenDetail.location" style="margin-top: 1em"></div>
            </section>

            <section id="map" class="map-section property__detail--topic"
                     style="border-top: 1px solid #EEEEEE; padding-top: 2rem; padding-bottom: 2rem;">
              <div class="anchor"></div>
              <h4 style="margin-bottom: 0; cursor: pointer" @click="isOpenDetail.map = !isOpenDetail.map">
                MAP & NEARBY
                <img src="@/assets/icons/icon_arrow-down.svg"
                     :class="{'close-detail': isOpenDetail.map}"
                     class="btn-open-detail">
              </h4>
              <div v-show="isOpenDetail.map" style="margin-top: 1em">
                <div v-if="propertyDetail.latitude !== '' && propertyDetail.longitude !== ''"
                     id="map-canvas" ref="mapCanvas" style="width: 100%; height: 400px; margin-bottom: 1em;"></div>
                <div id="nearby" v-html="propertyDetail.mapNearby"></div>
              </div>
            </section>

            <section id="community_features" class="property__detail--topic position-relative" v-if="propertyDetail.communityDetail !== ''">
              <div class="community_features-section anchor"></div>
              <h4 style="margin-bottom: 0; cursor: pointer" @click="isOpenDetail.communityFeature = !isOpenDetail.communityFeature">
                COMMUNITY FEATURES
                <img src="@/assets/icons/icon_arrow-down.svg"
                     :class="{'close-detail': isOpenDetail.communityFeature}"
                     class="btn-open-detail">
              </h4>
              <div v-html="propertyDetail.communityDetail" v-show="isOpenDetail.communityFeature" style="margin-top: 1em"></div>
            </section>
          </div>

          <project-overview
            :project-name="property.name"
            :project-overview-list="projectOverviewList"
          />
        </div>

        <property-inquiry
          :sale="sale"
          v-model="inquiry"
          @onSendEmailInquiryButtonClick="sendEmailInquiry"
          @onCloseFormInquiryButtonClick="closeInquiryForm" />
      </div>
      <!-- Similar Property -->
      <property-related-list
        v-if="Boolean(propertyDetail.showMoreProperty)"
        :related-property-list="relatedPropertyList"
        @onRelatedPropertyClick="viewPropertyDetail"/>
    </div>
  </main-template>
</template>

<script>
import AlertMixin from '@/mixins/alert-mixin'
import { LightGallery } from 'vue-light-gallery'

import { get } from 'lodash'
import { numberFormat, delay } from '@/helpers'
import {
  LOCATION,
  PROPERTY_TYPE,
  PROPERTY_TYPE_TITLE,
  PROPERTY_STATUS_TEXT,
  PROPERTY_ATTRIBUTE_LABEL,
  PROPERTY_ATTRIBUTE_DETAIL_FEATURE_LABEL,
  PROPERTY_ATTRIBUTE_PROJECT_OVERVIEW_LABEL,
  RENT_TYPE_TITLE,
  RENT_TYPE,
  API_KEY
} from '@/configs/constants'
import PropertyApi from '@/api/property-api'
import EmailApi from '@/api/email-api'
import GoogleMapsApiLoader from 'google-maps-api-loader'
import PropertyOverview from '@/views/Property/components/Overview'
import MainTemplate from '@/templates/MainTemplate'
import PropertyInquiry from '@/views/Property/components/Inquiry'
import PropertyPhotoGallery from '@/views/Property/components/PhotoGallery'
import PropertyRelatedList from '@/views/Property/components/Related'
import ProjectOverview from '@/views/Property/components/ProjectOverview'

export default {
  name: 'PropertyDetail',
  mixins: [AlertMixin],
  components: {
    PropertyRelatedList,
    PropertyPhotoGallery,
    PropertyInquiry,
    MainTemplate,
    PropertyOverview,
    ProjectOverview,
    LightGallery
  },
  data () {
    return {
      numberFormat,
      PROPERTY_STATUS_TEXT,
      PROPERTY_TYPE_TITLE,
      RENT_TYPE_TITLE,
      isLoading: false,
      floorPlanIndex: null,
      header: {
        style: 'height: 5vh;',
        className: 'hidden-search-box',
        imgPath: '',
        isShow: false,
        resizeLogo: false
      },
      footer: {
        className: 'property-detail'
      },
      isOpenDetail: {
        description: false,
        promotion: false,
        floorPlan: false,
        location: false,
        map: false,
        propertyDetail: false,
        communityFeature: false,
        projectOverview: false
      },
      property: {
        id: 0,
        name: '',
        type: '',
        saleId: 0,
        isSale: false,
        isRent: false,
        salePrice: 0,
        rentPrice: 0,
        rentType: RENT_TYPE.PER_DAY,
        bed: '',
        sleep: '',
        bath: '',
        buildingArea: '',
        landArea: '',
        livingArea: '',
        view: '',
        swimmingPool: '',
        parking: '',
        totalUnit: '',
        floor: '',
        floorInBuilding: '',
        furnish: '',
        landTitle: '',
        ownership: '',
        developer: '',
        rentGuarantee: 1,
        photoList: [],
        uploadPhotoList: [],
        status: 1
      },
      propertyDetail: {
        saleId: 0,
        location: '',
        locationDescription: '',
        latitude: '',
        longitude: '',
        mapNearby: '',
        description: '',
        featureDetail: '',
        communityDetail: '',
        mapDetail: '',
        marketingWord: '',
        promotionDetail: '',
        showMoreProperty: 0
      },
      attributes: [],
      detailAndFeatureList: [],
      projectOverviewList: [],
      sale: {
        id: 0,
        agentCode: '',
        firstName: '',
        lastName: '',
        nickName: '',
        email: '',
        phone: '',
        position: '',
        fax: '',
        lineId: '',
        isEnable: 1,
        profileImgFullUrl: ''
      },
      inquiry: {
        propertyId: 0,
        saleId: 0,
        saleEmail: '',
        saleName: '',
        fullName: '',
        email: '',
        phone: '',
        message: ''
      },
      photoList: [],
      floorPlanList: [],
      photoUrlList: [],
      floorPlanUrlList: [],
      relatedPropertyList: [],
      map: null,
      google: null,
      attributeSleep: '',
      attributeBed: '',
      attributeBath: '',
      attributeFloorInBuilding: '',
      attributeLivingArea: '',
      attributeLandArea: '',
      attributeBuildingArea: '',
      attributeYearFinished: '',
      attributeOwnership: '',
      attributeParking: '',
      attributeView: '',
      isDisplayInquiry: false
    }
  },
  watch: {
    async $route () {
      this.isLoading = true
      await this.preparePrefetchItems(false)
      this.isLoading = false
    }
  },
  computed: {
    prefetchItems () {
      return this.$store.state.prefetchItems
    }
  },
  async serverPrefetch () {
    await this.preparePrefetchItems(true)
  },
  async created () {
    try {
      this.isLoading = true
      await this.preparePrefetchItems(false)
      await delay(300)
      this.isLoading = false
    } catch (err) {
      // console.log(err)
    }
  },
  async updated () {
    if (this.propertyDetail.latitude !== '' && this.propertyDetail.longitude !== '') {
      const googleMapApi = await GoogleMapsApiLoader({
        apiKey: API_KEY.GOOGLE_MAP
      })
      this.google = googleMapApi

      this.initMap()
    }
  },
  methods: {
    async preparePrefetchItems (isServerPrefetch) {
      let prefetchItems = this.prefetchItems
      if (prefetchItems === null) {
        const propertyId = get(this.$route, 'params.propertyId', 0)
        const result = await PropertyApi.detail(propertyId)

        const overview = get(result, 'property', this.property)
        const detail = get(result, 'propertyDetail', this.propertyDetail)
        const attributeObjectList = get(result, 'attributeList', this.attributes)

        let attributeView = get(attributeObjectList, 'view', '')
        let attributeBed = get(attributeObjectList, 'bed', '')
        let attributeBath = get(attributeObjectList, 'bath', '')
        let attributeSleep = get(attributeObjectList, 'sleep', '')
        let attributeBuildingArea = get(attributeObjectList, 'buildingArea', '')
        let attributeLivingArea = get(attributeObjectList, 'livingArea', '')
        let attributeLandArea = get(attributeObjectList, 'landArea', '')
        let attributeFloorInBuilding = get(attributeObjectList, 'floorInBuilding', '')
        let attributeYearFinished = get(attributeObjectList, 'yearFinished', '')
        let attributeOwnership = get(attributeObjectList, 'ownership', '')
        let attributeParking = get(attributeObjectList, 'parking', '')

        const attributes = this._prepareAttributeList(attributeObjectList)
        const detailAndFeatureList = this._prepareDetailAndFeature(attributeObjectList)
        const projectOverviewList = this._prepareProjectOverview(attributeObjectList)

        const saleData = get(result, 'sale', this.sale)
        const photoList = overview.photoList.filter(photo => photo.type === 'property')
        const floorPlanList = overview.photoList.filter(photo => photo.type === 'floor-plan')
        const photoUrlList = overview.photoList.map(photo => photo.url)
        const floorPlanUrlList = floorPlanList.map(photo => photo.url)
        const relatedPropertyList = get(result, 'relatedPropertyList', [])

        const property = Object.assign({}, this.property, overview)
        const propertyDetail = Object.assign({}, this.propertyDetail, detail)
        const sale = Object.assign({}, this.sale, saleData)

        const coverImgUrl = get(photoList, '[0].url', '')
        const header = {
          style: 'height: 5vh;',
          className: 'hidden-search-box',
          imgPath: coverImgUrl,
          isShow: false,
          resizeLogo: false,
          companyLocation: {
            latitude: '',
            longitude: ''
          }
        }

        const footer = {
          className: 'property-detail'
        }

        prefetchItems = {
          header,
          footer,
          property,
          propertyDetail,
          attributeView,
          attributeSleep,
          attributeBed,
          attributeBath,
          attributeLivingArea,
          attributeLandArea,
          attributeBuildingArea,
          attributeFloorInBuilding,
          attributeYearFinished,
          attributeOwnership,
          attributeParking,
          attributes,
          detailAndFeatureList,
          projectOverviewList,
          sale,
          photoList,
          floorPlanList,
          photoUrlList,
          floorPlanUrlList,
          relatedPropertyList
        }

        this.scrollToTop()
      }

      if (isServerPrefetch) {
        this.$store.dispatch('initPrefetchItems', prefetchItems)
      } else {
        Object.assign(this, prefetchItems)
        this.$store.dispatch('clearPrefetchItems')
      }
    },
    _prepareAttributeList: function (attributeObjectList) {
      const excludeAttributeList = ['bed', 'bath', 'sleep', 'view']
      const attributeKeyList = Object.keys(attributeObjectList)
      const attributeList = attributeKeyList.filter(item => !excludeAttributeList.includes(item)).map((key) => {
        const label = PROPERTY_ATTRIBUTE_LABEL[key]
        let value = attributeObjectList[key]

        const attribute = {
          label: label,
          value: value
        }

        return attribute
      })

      return attributeList
    },
    _prepareDetailAndFeature: function (attributeObjectList) {
      const detailAndFeatureKeyList = Object.keys(PROPERTY_ATTRIBUTE_DETAIL_FEATURE_LABEL)

      const detailAndFeatureList = detailAndFeatureKeyList.map((key) => {
        const label = PROPERTY_ATTRIBUTE_DETAIL_FEATURE_LABEL[key]
        let value = attributeObjectList[key]

        const detailAndFeature = {
          label: label,
          value: value
        }

        return detailAndFeature
      })

      return detailAndFeatureList
    },
    _prepareProjectOverview: function (attributeObjectList) {
      const projectOverviewKeyList = Object.keys(PROPERTY_ATTRIBUTE_PROJECT_OVERVIEW_LABEL)

      const projectOverviewList = projectOverviewKeyList.map((key) => {
        const label = PROPERTY_ATTRIBUTE_PROJECT_OVERVIEW_LABEL[key]
        let value = attributeObjectList[key]

        const projectOverview = {
          label: label,
          value: value
        }

        return projectOverview
      })

      return projectOverviewList
    },
    getPropertyTypeIcon: function (type) {
      switch (type) {
        case PROPERTY_TYPE.VILLAS_AND_HOUSES:
          return 'icon_house.svg'
        default:
          return 'icon_condo.svg'
      }
    },
    getLocationTitle: function (locationId) {
      const result = LOCATION.find(item => item.id === locationId)

      if (result !== undefined) {
        return `${result.title}, ${result.district}, ${result.province}`
      }

      return 'N/A'
    },
    sendEmailInquiry: async function () {
      this.isLoading = true

      try {
        this.inquiry.propertyId = this.property.id
        this.inquiry.saleId = this.sale.id
        this.inquiry.saleName = `${this.sale.firstName} ${this.sale.lastName}`
        this.inquiry.saleEmail = this.sale.email

        await EmailApi.sendInquiry(this.inquiry)
      } catch (err) {
        // console.log(err)
      }

      await delay(300)

      this.inquiry = {
        propertyId: 0,
        saleId: 0,
        saleEmail: '',
        saleName: '',
        fullName: '',
        email: '',
        phone: '',
        message: ''
      }

      await delay(300)
      this.isLoading = false

      this.alertMessageSuccess('Send email success')
    },
    initMap: function () {
      const mapContainer = this.$refs.mapCanvas
      const myLatLng = {
        lat: parseFloat(this.propertyDetail.latitude),
        lng: parseFloat(this.propertyDetail.longitude)
      }
      this.map = new this.google.maps.Map(
        mapContainer, {
          center: myLatLng,
          zoom: 17
        })

      const marker = new this.google.maps.Marker({
        position: myLatLng,
        title: this.property.name,
        visible: true
      })

      marker.setMap(this.map)
    },
    viewPropertyDetail: function (propertyId) {
      window.location.href = `/property/detail/${propertyId}`
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    navButton (className) {
      this.isOpenDetail.map = true

      const el = this.$el.getElementsByClassName(className)[0]

      if (className === 'form__inquiry-section') {
        this._toggleInquiryForm()
      }

      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
    },
    _toggleInquiryForm () {
      this.isDisplayInquiry = !this.isDisplayInquiry
    },
    closeInquiryForm () {
      this.isDisplayInquiry = false
    }
  }
}
</script>

<style scoped>
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    text-align: left;
    padding-top: 75px;
    padding-bottom: 60px;
  }

  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 100%;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1300px;
    }
  }

  #nearby ul {
    margin-top: 2em;
    padding-left: 24px;
  }

  .property__detail--topic {
    border-top: 1px solid #EEEEEE;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  #description p+p {
    margin-top: 1em;
  }

  .list-horizontal ul {
    width: 100%;
  }

  .btn-open-detail {
    float: right;
    cursor: pointer;
    width: 16px;
    margin-top: 7px;
  }

  .close-detail {
    transform: rotate(180deg);
  }

  #photo-gallery > span, .lightgallery > span {
    padding-right: 5px;
  }
  .property-gallery {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .sale-rent-label {
    font-weight: 600 !important;
    opacity: .5 !important;
    font-size: 15px !important;
    color: #484848 !important;
  }

  @media (min-width: 768px) {
    .property-gallery {
      max-width: 180px;
      max-height: 120px;
    }
  }

  @media (max-width: 767px) {
    .property-gallery {
      max-width: 225px;
      max-height: 165px;
    }
  }

  @media (max-width: 375px) {
    .property-gallery {
      max-width: 350px;
      max-height: 200px;
    }
  }
</style>
